import { Container, CssBaseline, Stack } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import './App.css';
import TopBar from './components/TopBar'
import CollateAndDownloadButton from './components/CollateAndDownloadButton';
import UploadButton from './components/UploadButton';
import PostButton from './components/PostButton';

axios.defaults.baseURL = 'http://localhost:5000/';

function App() {
  return (
    <>
      <TopBar />
      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stack direction="row" spacing={1}>
            <Stack spacing={1}>
              <CollateAndDownloadButton endpoint="questionnaires" title="Questionnaires" />
              <CollateAndDownloadButton endpoint="registrations" title="Registrations" />
              <CollateAndDownloadButton endpoint="upfluencer" title="Upfluencer" />
              <CollateAndDownloadButton endpoint="feedback" title="Feedback" />
            </Stack>
            <Stack spacing={1}>
              <UploadButton endpoint="add-candidates" title="Add Candidates" />
              <UploadButton endpoint="advance-candidates" title="Advance Candidates" />
              <UploadButton endpoint="reject-candidates" title="Reject Candidates" />
              <UploadButton endpoint="accept-candidates" title="Accept Candidates" />
              <UploadButton endpoint="manual-override-add-testers" title="Manual Override Add Testers" />
              <UploadButton endpoint="manual-override-remove-testers" title="Manual Override Remove Testers" />
            </Stack>
            <Stack spacing={1}>
              <PostButton endpoint="sumo/daily-challenge" title="Regenerate daily challenge" />
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
}

export default App;

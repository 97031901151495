import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

const PostButton = ({ endpoint, title, data }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const post = async () => {
    try {
      setLoading(true);
      await axios.post(endpoint, data);

      enqueueSnackbar(`Successfully posted to ${endpoint}`, { variant: 'success' });
    }
    catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <label>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={post}
        fullWidth
      >
        {title}
      </LoadingButton>
    </label>
  );
}

export default PostButton;

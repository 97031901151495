import { React, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { Button, Link } from '@mui/material';

const loader = (status, error) => {
    switch (status) {
        case 'loading':
            return (<CircularProgress color="inherit" />);
        case 'error':
            return (
                <Tooltip title={error}>
                    <IconButton size="large" edge="end" color="inherit">
                        <ErrorIcon />
                    </IconButton>
                </Tooltip>
            );
        default: return undefined;
    }
}

export default function ButtonAppBar() {
    const [userId, setUserId] = useState('');
    const [awsEnv, setAwsEnv] = useState('');
    const [configuredEnv, setConfiguredEnv] = useState('')
    const [status, setStatus] = useState('loading')
    const [error, setError] = useState('');

    useEffect(() => {
        const f = async () => {
            try {
                const { data } = await axios.get('http://localhost:5000/user');
                const { email, environment, configuration } = data;
                setUserId(email);
                setAwsEnv(environment);
                setConfiguredEnv(configuration);
                setStatus('done');
            }
            catch (e) {
                setError(e.message);
                setStatus('error');
            }
        };
        f();
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography component="div" sx={{ flexGrow: 1 }}>
                        {
                            `${userId || 'Not signed in'} ` +
                            '(' +
                            `AWS: ${awsEnv || 'n/a'}, ` +
                            `Config: ${configuredEnv || 'n/a'}` +
                            ')'
                        }
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}>
                        <Button color="inherit" href="/server.zip" download>
                            Download Server
                        </Button>
                    </Box>
                    {loader(status, error)}
                </Toolbar>
            </AppBar>
        </Box>
    );
}

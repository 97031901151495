
import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

const CollateAndDownloadButton = ({ endpoint, title }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const download = async () => {
        try {
            setLoading(true);
            const { data } = await axios.get(`collate/${endpoint}`);

            enqueueSnackbar(`Successfully collated ${endpoint}`, { variant: 'success' });
            const link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute("download", '');
            link.click();
        }
        catch (e) {
            enqueueSnackbar(e, { variant: 'error' });
            console.log(e);
        }
        setLoading(false);
    }

    return (
        <LoadingButton
            loading={loading}
            variant="contained"
            onClick={download}
            fullWidth
        >
            Download {title}
        </LoadingButton>
    );
}

export default CollateAndDownloadButton;

import LoadingButton from '@mui/lab/LoadingButton';
import axios from 'axios';
import { useState } from 'react';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';

const UploadButton = ({ endpoint, title }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    const upload = async (file) => {
        if (!file) {
            console.log('no file selected');
            return;
        }
        try {
            setLoading(true);
            const form = new FormData();
            form.append('file', file);
            await axios.put(`s3/${endpoint}`, form, { headers: { "Content-Type": 'multipart/form-data' } });

            enqueueSnackbar(
                `Successfully uploaded ${file.name} to ${endpoint}`,
                { variant: 'success' }
            );
        }
        catch (e) {
            enqueueSnackbar(e, { variant: 'error' });
            console.log(e);
        }
        setLoading(false);
    }

    const Input = styled('input')({
        display: 'none',
    });

    return (
        <label>
            <Input accept="text/plain" type="file" onChange={async e => { await upload(e.target.files[0]); e.target.value = null; }} />
            <LoadingButton
                loading={loading}
                variant="contained"
                component="span"
                fullWidth
            >
                Upload {title}
            </LoadingButton>
        </label>
    );
}

export default UploadButton;
